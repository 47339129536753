<template>
  <widget-base
    :loading="loading"
    :title="getTitle"
    :no-data-icon="noDataIcon"
    :has-data="hasData"
    :can-refetch="canRefetch"
    class="h-full w-full"
    @refetch="refetch"
  >
    <div v-if="hasData" class="text-center h-full">
      <div
        class="h-full flex flex-col justify-center text-4xl font-bold relative"
        :class="{ 'text-5xl': isWhiteLabel }"
      >
        <span class="pb-8">
          {{
            $formatCurrency(
              selectedDatesSale.amount,
              selectedDatesSale.currency
            )
          }}
        </span>
      </div>
      <div
        v-if="currentComparisonSale"
        class="absolute w-full bottom-0 pb-4 px-4 text-base text-center font-normal"
      >
        {{ comparisonMessage }}
      </div>
    </div>
  </widget-base>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { differenceInDays, subDays, endOfDay, startOfDay } from 'date-fns'
import WidgetBase from '../widget-base'
import { isUnifiedCommerce } from '@/constants'

export default {
  name: 'Sales',
  components: {
    WidgetBase
  },
  props: {
    currentUserContext: {
      type: Object,
      default: () => ({})
    },
    widgetKey: {
      type: String,
      default: 'sales_range'
    },
    staticStart: {
      type: String,
      default: () => null
    },
    staticEnd: {
      type: String,
      default: () => null
    },
    noDataIcon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedDatesSales: [],
      comparisonDatesSales: [],
      loading: false,
      canRefetch: false,
      comparisonDaterange: {
        start: null,
        end: null
      }
    }
  },
  computed: {
    getTitle() {
      return {
        sales_range: this.$t('pages.home.widgets.sales_range.title'),
        sales_week: this.$t('pages.home.widgets.sales_week.title'),
        sales_today: this.$t('pages.home.widgets.sales_today.title')
      }[this.widgetKey]
    },
    selectedDatesSale() {
      return this.selectedDatesSales.find(
        ({ currency }) => currency === this.currentUserContext.selectedCurrency
      )
    },
    selectedDates() {
      return this.currentUserContext.selectedDates
    },
    isDynamicDate() {
      return !this.staticStart && !this.staticEnd
    },
    currentComparisonSale() {
      // find comparison amount by currency
      const currency = this.selectedDatesSale?.currency
      if (!currency) return

      return this.comparisonDatesSales.find(
        (sale) => sale.currency === currency
      )
    },
    comparisonMessage() {
      const options = {
        percentage: Math.abs(this.comparisonPercentage).toFixed(1),
        value: this.$formatCurrency(
          this.currentComparisonSale.amount,
          this.currentComparisonSale.currency
        )
      }

      const comparedToKey = this.comparisonPercentage > 0 ? '_higher' : '_lower'

      return {
        sales_range: this.$t(
          `pages.home.widgets.sales.sales_range.compared_to${comparedToKey}`,
          options
        ),
        sales_week: this.$t(
          `pages.home.widgets.sales.sales_week.compared_to${comparedToKey}`,
          options
        ),
        sales_today: this.$t(
          `pages.home.widgets.sales.sales_today.compared_to${comparedToKey}`,
          options
        )
      }[this.widgetKey]
    },
    comparisonPercentage() {
      if (!this.currentComparisonSale) return 0

      let ratio =
        (this.selectedDatesSale.amount / this.currentComparisonSale.amount -
          1) *
        100

      // rounding with 1 decimal precision
      return Math.round(ratio * 10) / 10
    },
    hasData() {
      return !!this.selectedDatesSale
    },
    resourceQuery() {
      // global branch filter overrides local branch filter
      let branchNumber
      if (Number.isFinite(this.currentUserContext.currentBranchNumber)) {
        branchNumber = this.currentUserContext.currentBranchNumber
      }

      return {
        location: this.currentUserContext.currentLocation || undefined,
        branch_number: branchNumber
      }
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  watch: {
    selectedDates: function (value) {
      // The daily and weekly values shouldnt change with a range change
      if (this.isDynamicDate) this.updateAllSales()
    }
  },
  mounted() {
    this.updateAllSales()
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    formatDate(date) {
      if (!date) return
      return this.$date.formatDate(date)
    },
    async updateSales(start, end, targetVar) {
      if (!start || !end) {
        this.comparisonDatesSales = []
        this.selectedDatesSales = []
        return
      }

      try {
        const { data } = await th
          .analytics({ timeout: 90000 })
          .getRevenuesSumForTimeRange({
            ...this.resourceQuery,
            start,
            end
          })
        const { values } = data[0]
        this[targetVar] = values
      } catch (e) {
        this.canRefetch = true
        this[targetVar] = []
      }
    },
    updateAllSales() {
      // properly display empty widget if filter has no valid dates
      const start = this.staticStart || this.selectedDates.start
      const end = this.staticEnd || this.selectedDates.end
      if (!start || !end) {
        this.comparisonDatesSales = []
        this.selectedDatesSales = []
        return
      }

      this.loading = true

      // In this widget the user will be shown not only the amount of sales but also a comparison to sales made in the past.
      // The user-selected sales will be compared to the sales generated in the same length of time, directly before the start of the user-selected daterange.
      // For example, if the user selected 7/22/2019 - 8/5/2019 the number of days is 15,
      // thus the comparison daterange is 7/21/2019 minus 15 days -> which is 7/7/2019 - 7/21/2019

      const numberOfDays = differenceInDays(new Date(end), new Date(start))

      this.comparisonDaterange.end = endOfDay(subDays(new Date(start), 1))
      this.comparisonDaterange.start = startOfDay(
        subDays(new Date(this.comparisonDaterange.end), numberOfDays)
      )

      Promise.all([
        this.updateSales(start, end, 'selectedDatesSales'),
        this.updateSales(
          this.comparisonDaterange.start,
          this.comparisonDaterange.end,
          'comparisonDatesSales'
        )
      ]).finally(() => {
        this.loading = false
      })
    },
    refresh() {
      this.$nextTick(() => {
        this.refetch()
      })
    },
    refetch() {
      this.updateAllSales()
      this.canRefetch = false
    }
  }
}
</script>

<style scoped>
.th-border-bottom-width {
  border-bottom-width: 13px;
}
</style>
